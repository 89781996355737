import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  PICTURE_USER,
  USER_OFFICES,
  SET_ROL_USER,
  SET_OLD_PASS,
  USER_CONCESSIONAIRE,
  NAV_CONTENT,
} from '../actions';

import rules from '../../config/rules';
import menuItems from '../../constants/menu';

const INIT_STATE = {
  rules:
      localStorage.getItem('role') !== null
          ? rules[`role_${localStorage.getItem('role')}`]
          : {},
  user: localStorage.getItem('user_id'),
  token: localStorage.getItem('token'),
  idRole: localStorage.getItem('role'),
  rolesList: JSON.parse(localStorage.getItem('rolesList')),
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  menuItems:
      localStorage.getItem('role') !== null
          ? menuItems[`role_${localStorage.getItem('role')}`]
          : [],
  userOffices: '',
  oldPass:
      localStorage.getItem('oldPass') === null
          ? false
          : localStorage.getItem('oldPass'),
  concesionario: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case LOGIN_USER:
          return {
              ...state,
              loading: true,
              error: '',
          };
      case LOGIN_USER_SUCCESS:
          return {
              ...state,
              loading: false,
              user: action.payload.user.pk,
              error: '',
              token: action.payload.token,
              rules: action.payload.rules,
              menuItems: action.payload.menuItems,
              idRole: localStorage.getItem('role'),
              rolesList: JSON.parse(localStorage.getItem('rolesList')),
              oldPass: localStorage.getItem('oldPass'),
          };
      case LOGIN_USER_ERROR:
          return {
              ...state,
              loading: false,
              user: '',
              error: action.payload.message,
          };

      case FORGOT_PASSWORD:
          return {
              ...state,
              loading: true,
              error: '',
          };
      case FORGOT_PASSWORD_SUCCESS:
          return {
              ...state,
              loading: false,
              forgotUserMail: action.payload,
              error: '',
          };
      case FORGOT_PASSWORD_ERROR:
          return {
              ...state,
              loading: false,
              forgotUserMail: '',
              error: action.payload.message,
          };

      case RESET_PASSWORD:
          return {
              ...state,
              loading: true,
              error: '',
          };
      case RESET_PASSWORD_SUCCESS:
          return {
              ...state,
              loading: false,
              newPassword: action.payload,
              resetPasswordCode: '',
              error: '',
          };
      case RESET_PASSWORD_ERROR:
          return {
              ...state,
              loading: false,
              newPassword: '',
              resetPasswordCode: '',
              error: action.payload.message,
          };
      case LOGOUT_USER:
          return {
              ...state,
              user: null,
              error: '',
          };
      case PICTURE_USER:
          return {
              ...state,
              picture: localStorage.getItem('picture'),
          };
      case USER_OFFICES:
          return {
              ...state,
              userOffices: action.payload,
          };
      case SET_ROL_USER:
          return {
              ...state,
              menuItems:
                  localStorage.getItem('role') !== null
                      ? menuItems[`role_${localStorage.getItem('role')}`]
                      : [],
              rules:
                  localStorage.getItem('role') !== null
                      ? rules[`role_${localStorage.getItem('role')}`]
                      : {},
              idRole: action.payload,
          };
      case SET_OLD_PASS:
          return {
              ...state,
              oldPass: action.payload,
          };
      case USER_CONCESSIONAIRE:
          return {
              ...state,
              concesionario: action.payload,
          };
      case NAV_CONTENT:
          return {
              ...state,
              rules: {
                  ...state.rules,
                  views: {
                      ...state.rules['views'],
                      create: {
                          ...state.rules.views['create'],
                          component: action.payload,
                      },
                  },
              },
          };
      default:
          return {
              ...state,
          };
  }
};
