import menuAdministrator from './menu/menuAdministrator';
import menuVendedor from './menu/menuVendedor';
import menuContenidoDigital from './menu/menuContenidoDigital';
import menuCorresponsal from './menu/menuCorresponsal';
import menuFacturacion from './menu/menuFacturacion';
import menuOrdenServicio from './menu/menuOrdenServicio';
import menuPuntoVenta from './menu/menuPuntoVenta';
import menuSupervisorCorresponsal from './menu/menuSupervisorCorresponsal';
import menuSupervisorContenidoDigital from './menu/menuSupervisorContenidoDigital';

export default Object.assign(
    [],
    menuAdministrator,
    menuVendedor,
    menuContenidoDigital,
    menuCorresponsal,
    menuFacturacion,
    menuOrdenServicio,
    menuPuntoVenta,
    menuSupervisorCorresponsal,
    menuSupervisorContenidoDigital
);
