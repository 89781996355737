import {
    iconRequest,
    iconClient,
    iconReports,
    iconParameters,
    iconCalculator,
    iconReportsDefault,
    iconParametersDefault,
    iconSearch,
    iconPaper,
} from '../defaultValues';

const data = {
    role_7aa8d689df234eeaba954a9e9d1a84d5: [
        {
            id: 'factura',
            icon: iconPaper,
            label: 'Factura',
            to: `/second-menu`,
            subs: [
                {
                    id: 'preInvoice',
                    icon: 'simple-icon-paper-plane',
                    label: 'Prefactura',
                    to: `/app/preInvoice`,
                },
                {
                    id: 'parametrizacion',
                    icon: 'simple-icon-paper-plane',
                    label: 'Parametrización cierre contable',
                    to: `/app/accountingClosingParameterization`,
                },
                {
                    id: 'balanceSheet',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cierre contable',
                    to: `/app/balanceSheet`,
                },
                {
                    id: 'invoiceClient',
                    icon: 'simple-icon-paper-plane',
                    label: 'Historico factura por periodo',
                    to: `/app/invoiceClient`,
                },
                {
                    id: 'listHistory',
                    icon: 'simple-icon-paper-plane',
                    label: 'Historico factura por cliente',
                    to: `/app/preInvoice/listHistory`,
                }
            ],
        },
    ],
};
export default data;
