import {
    iconRequest,
    iconClient,
    iconReports,
    iconParameters,
    iconCalculator,
    iconReportsDefault,
    iconParametersDefault,
    iconSearch,
    iconPaper,
} from '../defaultValues';

const data = {
    role_14177e0ed735427ca8f047de901ac0fe: [
        {
            id: 'tramites',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Tramites',
            to: `/second-menu`,
            subs: [
                {
                    id: 'tipoTramite',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo Tramites',
                    to: `/app/typeProcedure`,
                },
                {
                    id: 'tramitesLinea',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tramites en Linea',
                    to: `/app/processOnline`,
                },
                {
                    id: 'tramitesEspeciales',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tramites Especiales',
                    to: `/app/specialProcedures`,
                },
                {
                    id: 'listadoTramites',
                    icon: 'simple-icon-paper-plane',
                    label: 'Listado Tramites',
                    to: `/app/procedures`,
                }
            ],
        },
    ],
};
export default data;
