export const PROPERTIES = {
    ADDRESS: { required: true, maxLength: 200, minLength: 2 },
    PHONE: {
        required: true,
        regExp: /[2-8][0-9]{2}-[0-9]{4}|[2-8][0-9]{6}/,
    },
    CELL_PHONE: {
        required: true,
        regExp: /^(300|301|302|303|304|324|305|310|311|312|313|314|320|321|322|323|315|316|317|318|319|319|350|351|302|323|324|324|333)-[0-9]{3}-[0-9]{4}|^(300|301|302|303|304|324|305|310|311|312|313|314|320|321|322|323|315|316|317|318|319|319|350|351|302|323|324|324|333)[0-9]{7}/,
    },

    EMAIL: {
        required: false,
        regExp: /^((?!tiene|TIENE|CORREO|correo)[a-zA-Z0-9.\-_]){3,}@(gmail|GMAIL|HOTMAIL|hotmail|YAHOO|yahoo|OUTLOOK|outlook).[a-zA-Z.]+$/,
    },
};

export const ROLES = {
    ADMINISTRADOR: 1,
    DISTRIBUIDOR: 3,
    FACTURACION: 4,
    CONTENIDO_DIGITAL: 5,
    CORRESPONSAL: 6,
    ORDEN_SERVICIO: 7,
    PUNTO_VENTA: 8,
    SUPERVISOR_CORRESPONSAL: 9,
    SUPERVISOR_CONTENIDO_DIGITAL: 10,
};
