const data = {
    role_907bac229f6c4cd7b9ed3e733e8f7e10: [
        {
            id: 'corresponsal',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Corresponsal bancario',
            to: `/second-menu`,
            // roles: [UserRole.Admin, UserRole.Editor],
            subs: [
                {
                    id: 'correspondentOperation',
                    icon: 'simple-icon-paper-plane',
                    label: 'Corresponsal',
                    to: `/app/correspondentOperation/listCorrespondent`,
                },
                {
                    id: 'typeBankingCorrespondent',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo Corresponsal',
                    to: `/app/typeBankingCorrespondent`,
                },
                {
                    id: 'bankingCorrespondent',
                    icon: 'simple-icon-paper-plane',
                    label: 'Acción Corresponsal',
                    to: `/app/bankingCorrespondent`,
                },
                {
                    id: 'correspondentBalance',
                    icon: 'iconsminds-financial',
                    label: 'Saldos Corresponsales',
                    to: `/app/correspondentBalance`,
                },
                {
                    icon: 'iconsminds-dollar',
                    label: 'Recargas',
                    to: `/app/recharge`,
                },
            ],
        },
    ],
};
export default data;
