import { VALIDATIONS_SET_DATA } from '../actions';

const INIT_STATE = {
    requiresSpouse: false,
    requiresSpouseCodebtor: false,
    createCodebtor: false,
    enableContactCodebtor: false,
    enableSpouseCodebtor: false,
    enableHeritageCodebtor: false,
    finishStepsCodebtor: false,
    returnToComponentCodebtor: false,
    editProcessWizard: false,
    refreshListCredit: false,
    loadingCalculator: false,
    errorCalculatorCredit: false,
    codebtorExist: false,
    informationCreditErrors: null,
    isLastStep: false,
    nextStepControl: false,
    inputsFinalRequest: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case VALIDATIONS_SET_DATA:
            return Object.assign({}, state, action.payload);
        default:
            return { ...state };
    }
};
