import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import globals from './globals/reducer';
import dependencies from './dependencies/reducer';
import errors from './errors/reducer';
import general from './general/reducer';
import validations from './validations/reducer';

import { LOGOUT_USER } from './actions';

const reducersList = combineReducers({
    menu,
    settings,
    authUser,
    globals,
    errors,
    dependencies,
    general,
    validations,
});

const reducers = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === LOGOUT_USER) state = undefined;
    return reducersList(state, action);
};

export default reducers;

