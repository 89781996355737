import {
    iconRequest,
    iconClient,
    iconReports,
    iconParameters,
    iconCalculator,
    iconReportsDefault,
    iconParametersDefault,
    iconSearch,
    iconPaper,
} from '../defaultValues';

const data = {
    role_7ace8e3bf4ae4a048d0ef273117875d2: [
        {
            id: 'digital',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Contenido digital',
            to: `/app/acquireAccount`,
        },
        {
            id: 'corresponsal',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Corresponsal bancario',
            to: `/app/correspondentOperation`,
        },
        {
            id: 'tramites',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Tramites',
            to: `/app/procedures`,
        },
        {
            id: 'recargas',
            icon: 'iconsminds-dollar',
            label: 'Recargas',
            to: `/app/recharge`,
        },
        {
            id: 'balanceCash',
            icon: 'simple-icon-paper-plane',
            label: 'Cuadre de saldos',
            to: `/app/balanceCash`,
        },
        {
            id: 'factura',
            icon: iconPaper,
            label: 'Factura',
            to: `/second-menu`,
            subs: [
                {
                    id: 'listPendingPayment',
                    icon: 'simple-icon-paper-plane',
                    label: 'Pagos pendientes',
                    to: `/app/preInvoice/listPendingPayment`,
                },
                {
                    id: 'listRenameInvoiceProcess',
                    icon: 'simple-icon-paper-plane',
                    label: 'Factura en proceso',
                    to: `/app/preInvoice/listRenameInvoiceProcess`,
                },
                {
                    id: 'listHistory',
                    icon: 'simple-icon-paper-plane',
                    label: 'Historico factura',
                    to: `/app/preInvoice/listHistory`,
                },
            ],
        },
        {
            id: 'blankpage',
            icon: iconReports,
            label: 'Reportes',
            to: '/app/reports',
        },
    ],
};
export default data;
