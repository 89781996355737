import {
	ERROR_INFORMATION_CREDIT
} from '../actions';

const INIT_STATE = {
    informationCredit: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case ERROR_INFORMATION_CREDIT:
			return Object.assign({}, state, {informationCredit : action.payload})
		default: return { ...state };
	}
}