import { GLOBAL_SET_DATA } from '../actions';

const INIT_STATE = {
    producto: [],
    subproducto: [],
    oficina: [],
    regional: [],
    departamentos_regional: '',
    tipo_corresponsal_bancario: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GLOBAL_SET_DATA:
            return Object.assign({}, state, action.payload);

        default:
            return { ...state };
    }
};
