/**
 *
 * @param {*} product
 */
export function monthsProduct(product) {
    var minMonth = 3;
    var maxMonth = 36;
    var months = [];

    if (product === 2) {
        maxMonth = 48;
    }

    if (product === 17 || product === 40) {
        maxMonth = 60;
    }
    //se modifica plazo por solicitud de mauricio garcias , ajustes fondos de garantias
    if (product === 18) {
        minMonth = 1;
        maxMonth = 60;
    }

    for (let index = minMonth; index <= maxMonth; index++) {
        months.push({
            label: index === 1 ? `${index} Mes` : `${index} Meses`,
            value: index,
        });
    }
    return months;
}

/**
 *
 * @param {*} type
 * @param {*} message
 */
export function validationDefault(type, column) {
    var obj = {};
    type.map((item) => {
        return (obj[item] = {
            value: true,
            message: typeValidation(item, column),
        });
    });
    return obj;
}

function typeValidation(type, column) {
    switch (type) {
        case 'required':
            return `El campo ${column} es requerido`;
        default:
            return;
    }
}

export function validationDefaultNew(data, column) {
    var obj = {};
    data.map((item) => {
        return (obj[item.type] = {
            value: item.value,
            message: typeValidationNew(item, column),
        });
    });
    return obj;
}

function typeValidationNew(item, column) {
    switch (item.type) {
        case 'required':
            return `El campo ${column} es requerido`;
        case 'maxLength':
            return `El campo ${column} debe ser maximo a ${item.value} caracteres`;
        case 'minLength':
            return `El campo ${column} debe ser minimo a ${item.value} carácteres`;
        case 'min':
            return `El campo ${column} debe ser minimo ${item.value}`;
        case 'max':
            return `El campo ${column} debe ser maximo ${item.value}`;
        case 'pattern':
            return item.mesagge
                ? item.mesagge
                : `El campo ${column} debe ser un correo electrónico valido`;
        case 'maxValueString':
            return `El campo ${column} debe ser maximo ${item.value}`;
        default:
            return;
    }
}

export function defaultYearReport() { 

    let date = new Date();
    let year = date.getFullYear();
    return {value: year, label: year};
}
