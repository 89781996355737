import {
    MOSTRAR_CAMPOS_PERFIL,
    EJECUTAR_API_SALDO_USUARIO,
    NOTIFICACIONES_CORRESPONSAL,
    DATA_IMPRIMIR,
    GENERAR_IMPRESION,
    RECARGAR_CIERRE_CONTABLE,
    LOADING_GENERAL,
    SALDO_CORRESPONSAL_BANCARIO,
    LOADING_LISTADO_CORRESPONSAL

 } from '../actions';

export const setMostrarCamposPerfil = (data) => {
    return { type: MOSTRAR_CAMPOS_PERFIL, payload: data };
};

export const setEjecutarApiSaldoUsuario = (data) => {
    return { type: EJECUTAR_API_SALDO_USUARIO, payload: data };
};

export const setNotificacionCorresponsal = (data) => {
    return {
        type: NOTIFICACIONES_CORRESPONSAL,
        payload: { notificacionesCorresponsal: data },
    };
};

export const setDataImprimir = (data) => {
    return {
        type: DATA_IMPRIMIR,
        payload: { dataImprimir: data },
    };
};

export const setGenerarImpresion = (data) => {
    return { type: GENERAR_IMPRESION, payload: data };
};

export const setRecargarCierreContable = (data) => {
    return { type: RECARGAR_CIERRE_CONTABLE, payload: data };
};

export const setLoadingGeneral = (data) => {
    return { type: LOADING_GENERAL, payload: data };
};

export const setSaldoCorresponsalBancarioGenral = (data) => {
    return { type: SALDO_CORRESPONSAL_BANCARIO, payload: data };
};

export const setLoadingListadoCorresponsal = (data) => {
    return { type: LOADING_LISTADO_CORRESPONSAL, payload: data };
};