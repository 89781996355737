import {
    iconRequest,
    iconClient,
    iconReports,
    iconParameters,
    iconCalculator,
    iconReportsDefault,
    iconParametersDefault,
    iconSearch,
    iconPaper,
} from '../defaultValues';

const data = {
    role_b0f7c326f7a54c1fa6f3a48454f2c95f: [
        {
            id: 'gogo',
            icon: 'iconsminds-administrator',
            label: 'Administración',
            to: `/gogo`,
            subs: [
                {
                    icon: 'simple-icon-user',
                    label: 'Usuario',
                    to: `/app/users`,
                },
                {
                    icon: 'iconsminds-dollar',
                    label: 'Recargas',
                    to: `/app/recharge`,
                },
                {
                    icon: 'iconsminds-pen-2',
                    label: 'Parametros contenido digital',
                    to: `/app/salesInvoiceParameters`,
                },
                {
                    icon: 'iconsminds-pen-2',
                    label: 'Parametros corresponsal',
                    to: `/app/correspondentParameters`,
                },
                {
                    icon: 'iconsminds-pen-2',
                    label: 'Parametros Factura',
                    to: `/app/invoiceParameter`,
                },
                {
                    id: 'balanceCash',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cuadre de saldos',
                    to: `/app/balanceCash`,
                },
            ],
        },
        {
            id: 'digital',
            icon: 'iconsminds-digital-drawing',
            label: 'Contenido digital',
            to: `/second-menu`,
            subs: [
                {
                    id: 'acquireAccount',
                    icon: 'iconsminds-tablet-3',
                    label: 'Adquirir cuenta',
                    to: `/app/acquireAccount`,
                },
                {
                    id: 'account',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cuenta',
                    to: `/app/account`,
                },
                {
                    id: 'accountType',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo cuenta',
                    to: `/app/accountType`,
                },
                {
                    id: 'accountAvailable',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cuentas disponibles',
                    to: `/app/accountAvailable`,
                },
            ],
        },
        {
            id: 'corresponsal',
            icon: 'iconsminds-bank',
            label: 'Corresponsal bancario',
            to: `/second-menu`,
            // roles: [UserRole.Admin, UserRole.Editor],
            subs: [
                {
                    id: 'correspondentOperation',
                    icon: 'simple-icon-paper-plane',
                    label: 'Corresponsal',
                    to: `/app/correspondentOperation`,
                },
                {
                    id: 'typeBankingCorrespondent',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo Corresponsal',
                    to: `/app/typeBankingCorrespondent`,
                },
                {
                    id: 'bankingCorrespondent',
                    icon: 'simple-icon-paper-plane',
                    label: 'Acción Corresponsal',
                    to: `/app/bankingCorrespondent`,
                },
                {
                    id: 'mediumCorrespondentOperation',
                    icon: 'simple-icon-paper-plane',
                    label: 'Medio Operación Corresponsal',
                    to: `/app/mediumCorrespondentOperation`,
                },
                {
                    id: 'correspondentBalance',
                    icon: 'iconsminds-financial',
                    label: 'Saldos Corresponsales',
                    to: `/app/correspondentBalance`,
                },

                // {
                //     id: 'printTicket',
                //     icon: 'simple-icon-paper-plane',
                //     label: 'Imprimir',
                //     to: `/app/bankingCorrespondent/printTicket`,
                // },
            ],
        },
        {
            id: 'tramites',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Tramites',
            to: `/second-menu`,
            subs: [
                {
                    id: 'tipoTramite',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo Tramites',
                    to: `/app/typeProcedure`,
                },
                {
                    id: 'tramitesLinea',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tramites en Linea',
                    to: `/app/processOnline`,
                },
                {
                    id: 'tramitesEspeciales',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tramites Especiales',
                    to: `/app/specialProcedures`,
                },
                {
                    id: 'listadoTramites',
                    icon: 'simple-icon-paper-plane',
                    label: 'Listado Tramites',
                    to: `/app/procedures`,
                },
            ],
        },
        {
            id: 'factura',
            icon: iconPaper,
            label: 'Factura',
            to: `/second-menu`,
            subs: [
                {
                    id: 'preInvoice',
                    icon: 'simple-icon-paper-plane',
                    label: 'Factura',
                    to: `/app/preInvoice`,
                },
                {
                    id: 'parametrizacion',
                    icon: 'simple-icon-paper-plane',
                    label: 'Parametrización cierre contable',
                    to: `/app/accountingClosingParameterization`,
                },
                {
                    id: 'balanceSheet',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cierre contable',
                    to: `/app/balanceSheet`,
                },
                {
                    id: 'invoiceClient',
                    icon: 'simple-icon-paper-plane',
                    label: 'Historico factura por periodo',
                    to: `/app/invoiceClient`,
                },
                {
                    id: 'listHistory',
                    icon: 'simple-icon-paper-plane',
                    label: 'Historico factura por cliente',
                    to: `/app/preInvoice/listHistory`,
                },
                {
                    id: 'listPaymentChannels',
                    icon: 'simple-icon-paper-plane',
                    label: 'Canales de pago',
                    to: `/app/paymentChannels/list`,
                },
            ],
        },
        {
            id: 'blankpage',
            icon: iconReports,
            label: 'Reportes',
            to: '/app/blank-page',
            subs: [
                {
                    icon: iconReportsDefault,
                    label: 'Generales',
                    to: '/app/reports',
                },
            ],
        },
    ],
};
export default data;
