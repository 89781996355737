import { DEPENDENCE_SET_DATA } from '../actions';

const INIT_STATE = {
    departamento: null,
    marca: null,
    producto: null,
    fng_producto: null,
    sucursales: null,
    oficinas: null,
    oficina: null,
    concesionarios_aliados: null,
    groups: null,
    ciudad: null,
    ciudad_contacto: null,
    ciudad_negocio: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DEPENDENCE_SET_DATA:
            return Object.assign({}, state, action.payload);
        default:
            return { ...state };
    }
};
